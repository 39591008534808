<template>
  <div id="navbar" class="navbar" tabindex="0">
    <div class="navbar-left">
      <router-link to="/">
        <img class="navbar-logo" src="@/assets/logoSirius.png" alt="" />
      </router-link>
    </div>
    <div class="navbar-wrapper">
      <router-link :to="{ path: '/' }" class="nav-link">
        {{ $t("NavBar.about") }}
      </router-link>
      <router-link :to="{ path: '/team' }" class="nav-link">
        {{ $t("NavBar.team") }}
      </router-link>
      <div
        class="navbar-dropdown"
        @mouseover="isDropVisible = true"
        @mouseleave="isDropVisible = false"
      >
        <div class="nav-link">
          {{ $t("NavBar.project") }}
          <img class="chevron-icon" src="@/assets/chevron-down.svg" alt="" />
        </div>
        <transition name="dropdown">
          <div v-if="isDropVisible" class="navbar-dropdown-menu">
            <div class="navbar-dropdown-menu-wrapper">
              <router-link :to="{ path: '/qmsat'}" class="nav-link">
                {{ $t("NavBar.QMSat") }}
              </router-link>
              <router-link :to="{ path: '/qmsatdata'}" class="nav-link">
                {{ $t("NavBar.QMSatData") }}
              </router-link>
              <router-link :to="{ path: '/' }" class="nav-link">
                {{ $t("NavBar.future") }}
              </router-link>
            </div>
          </div>
        </transition>
      </div>
      <router-link :to="{ path: '/actualities' }" class="nav-link">
        {{ $t("NavBar.actuality") }}
      </router-link>
      <div class="navbar-right-box">
        <router-link :to="{ path: '/contact' }" class="nav-link">
          {{ $t("NavBar.contact") }}
        </router-link>
        <LangButton />
      </div>
    </div>
    <div @click="toggleSideBar" class="navbar-sideBtn">
      <img src="@/assets/sideBar.svg" alt="" />
    </div>
  </div>
  <transition name="slide" mode="out-in">
    <div id="sidebar-navMenu" v-if="showSideBar">
      <div class="sidebar">
        <div class="sidebar-top">
          <router-link class="sidebar-logo-box" to="/">
            <img class="sidebar-logo" src="@/assets/logoSirius.png" alt="" />
          </router-link>
          <div class="sidebar-top-close" @click="toggleSideBar">
            <img src="@/assets/close.svg" alt="" />
          </div>
        </div>
        <div class="sidebar-content">
          <router-link :to="{ path: '/' }" class="nav-link">
            {{ $t("NavBar.about") }}
          </router-link>
          <div class="sidebar-line"></div>
          <router-link :to="{ path: '/team' }" class="nav-link">
            {{ $t("NavBar.team") }}
          </router-link>
          <div class="sidebar-line"></div>
          <div>
            <div class="nav-link" @click="toggleDropdown">
              {{ $t("NavBar.project") }}
              <img
                class="chevron-icon"
                src="@/assets/chevron-down.svg"
                alt=""
              />
            </div>
            <collapse-transition :duration="500" easing="ease">
              <div v-show="isDropVisible" class="sidebar-dropdown">
                <router-link :to="{ path: '/qmsat' }" class="nav-link">
                  {{ $t("NavBar.QMSat") }}
                </router-link>
                <router-link :to="{ path: '/qmsatdata' }" class="nav-link">
                  {{ $t("NavBar.QMSatData") }}
                </router-link>
                <router-link :to="{ path: '/' }" class="nav-link">
                  {{ $t("NavBar.future") }}
                </router-link>
              </div>
            </collapse-transition>
          </div>
          <div class="sidebar-line"></div>
          <router-link :to="{ path: '/actualities' }" class="nav-link">
            {{ $t("NavBar.actuality") }}
          </router-link>
          <div class="sidebar-line"></div>
          <router-link :to="{ path: '/contact' }" class="nav-link">
            {{ $t("NavBar.contact") }}
          </router-link>
          <div class="sidebar-line"></div>
          <LangButton :text="true" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LangButton from "@/components/LangButton.vue";
export default {
  components: {
    LangButton,
  },
  data() {
    return {
      isDropVisible: false,
      showSideBar: false,
    };
  },
  methods: {
    async closeSideBar() {
      this.showSideBar = false;
    },
    async toggleSideBar() {
      if (!this.showSideBar) {
        await this.wait(700);
      }
      this.showSideBar = !this.showSideBar;
    },
    toggleDropdown() {
      this.isDropVisible = !this.isDropVisible;
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async toggleMenu() {
      document.getElementById("myDropdown").classList.add("hideDropdown");
      await this.wait(100);
      document.getElementById("myDropdown").classList.remove("hideDropdown");
      document.getElementById("myDropdown").classList.remove("showDropdown");
    },
    showDropdown() {
      document.getElementById("myDropdown").classList.add("showDropdown");
    },
  },
};
</script>

<style lang="scss" scoped>
#sidebar-navMenu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 3000;
}
a:hover {
  text-decoration: none;
  color: none !important;
}
.left-nav-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  height: 100px;
  // width: 145px;
  background-color: transparent;
  transition: all ease 0.2s;
  outline: 0;
  align-items: center;
  padding: 0 30px;
  overflow: hidden;
  // border-right: 10px solid $gray-blue;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  &-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    gap: 20px;
  }
  &-wrapper {
    background: #43443b;
    height: 40px !important;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    max-width: 1000px;
    gap: 20px;
    padding: 10px;
  }
  &-sideBtn {
    display: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    filter: invert(51%) sepia(8%) saturate(3258%) hue-rotate(103deg)
      brightness(82%) contrast(83%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    gap: 20px;
  }
  &-logo {
    display: flex;
    height: auto;
    width: 250px;
    position: relative;
    top: 12px;
    left: -42px;
  }
  // NAVBAR DROPDOWN -------------------
  &-dropdown {
    &-menu {
      position: absolute;
      top: 50px;
      padding: 30px 0px;
      &-wrapper {
        padding: 25px 55px;
        background: #43443b;
        min-width: 100px;
        & a {
          display: block;
          padding: 10px 0px;
        }
      }
    }
  }
}
@keyframes slideUp-small {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
// DROPDOWN ANIMATION -----------------------------
.dropdown-enter-active {
  animation: slideUp-small 0.5s ease;
}
.dropdown-leave-active {
  animation: slideUp-small 0.2s ease reverse;
}
.nav-link {
  text-align: left;
  font-weight: bolder;
  padding: 0px 12px;
  font-size: 20px;
  color: white !important;
  transition: all 0.4s ease;
  text-decoration: none;
  .chevron-icon {
    filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(352deg)
      brightness(103%) contrast(100%);
    margin-left: 5px;
  }
}
.nav-link:hover {
  color: #2b8560 !important;
  text-decoration: none;
  cursor: pointer;
  .chevron-icon {
    filter: invert(44%) sepia(17%) saturate(1414%) hue-rotate(104deg)
      brightness(95%) contrast(90%);
  }
}

// sidebar -----------------------------

.sidebar {
  background-color: #43443b;
  height: 100vh;
  padding-top: 30px;
  text-align: left;
  box-shadow: 7px 0px 30px 6px rgb(0 0 0 / 25%);
  width: 300px;
  /* backdrop-filter: blur(10px) !important; */
  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    margin-bottom: 30px;
    overflow: hidden;
    &-title {
      font-size: 30px;
    }
    &-close {
      cursor: pointer;
      width: 20px;
      height: 20px;
      & img {
        width: 100%;
        height: 100%;
        filter: invert(98%) sepia(44%) saturate(4846%) hue-rotate(325deg)
          brightness(94%) contrast(91%);
      }
    }
  }
  &-dropdown {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0 30px;
    .nav-link {
      padding: 0;
    }
    .nav-link:hover {
      color: #2b8560 !important;
      text-decoration: none;
    }
  }
  &-logo {
    width: 250px;
    height: auto;
    position: relative;
    top: -95px;
    left: -45px;
    &-box {
      overflow: hidden;
      width: 200px;
      height: 50px;
    }
  }
  &-line {
    width: 100%;
    height: 3px;
    background: #2b8560;
  }
}
@keyframes SlideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-enter-active {
  animation: SlideInRight;
  /* referring directly to the animation's @keyframe declaration */
  -webkit-animation: SlideInRight;
  -moz-animation: SlideInRight;
  animation-duration: 0.5s;
  /* don't forget to set a duration! */
}

.slide-leave-active {
  animation: SlideInRight reverse;
  /* referring directly to the animation's @keyframe declaration */
  -moz-animation: SlideInRight reverse;
  -webkit-animation: SlideInRight reverse;
  animation-duration: 0.5s;
  /* don't forget to set a duration! */
}

@media (max-width: 1200px) {
  .navbar {
    &-right-box {
      width: 20%;
    }
  }
}

@media (max-width: 1024px) {
  .navbar {
    &-left {
      width: 30%;
    }
    &-wrapper {
      display: none;
    }
    &-right-box {
      display: none;
    }
    &-sideBtn {
      display: block;
      cursor: pointer;
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .account {
    margin-bottom: 20px;
    & img {
      height: auto;
      margin: 3px auto;
      display: block;
    }
    & span {
      margin-right: 0px;
    }
  }
}
</style>