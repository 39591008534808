<template>
  <div class="team">
    <div class="team-section">
      <h1 class="team-title">{{ $t("Teams.team") }}</h1>
      <p class="team-description">
        {{ $t("Teams.teamDescription") }}
      </p>
    </div>
    <img
      src="@/assets/equipeSirius.png"
      alt="sirius team picture"
      class="team-image"
    />
    <div class="team-section">
      <h1 class="team-title">{{ $t("Teams.member") }}</h1>
      <p class="team-description">
        {{ $t("Teams.memberDescription") }}
      </p>
    </div>
    <div class="team-grid">
      <TeamCard v-for="member in members" :key="member.name" :member="member" />
    </div>
  </div>
</template>

<script>
import TeamCard from "@/components/TeamCard.vue";
export default {
  components: {
    TeamCard,
  },
  data() {
    return {
      members: [
        {
          name: "Maxime Croteau",
          role: this.$t("Teams.roleMaxCroteau"),
          img: require("@/assets/MaxCrot.png"),
        },
        {
          name: "Juliette Lacasse",
          role: this.$t("Teams.roleJuliette"),
          img: require("@/assets/Ju.png"),
        },
        {
          name: "Pascal-Emmanuel Lachance",
          role: this.$t("Teams.rolePetitPascal"),
          img: require("@/assets/PP.png"),
        },
        {
          name: "Maxime Couture",
          role: this.$t("Teams.roleMaxCouture"),
          img: require("@/assets/MaxCout.png"),
        },
        {
          name: "Julien Boily",
          role: this.$t("Teams.roleJulien"),
          img: require("@/assets/Boily.png"),
        },
        {
          name: "Anthony St-Laurent Cyr",
          role: this.$t("Teams.roleLeadWeb"),
          img: require("@/assets/Anthony.png"),
        },
        {
          name: "Clémence Lekouma",
          role: this.$t("Teams.roleMec"),
          img: require("@/assets/Clemence.png"),
        },
        {
          name: "Jean-Frédéric Gariépy",
          role: this.$t("Teams.roleGroundStation"),
          img: "",
        },
        
        {
          name: "Massimo Di Maulo",
          role: this.$t("Teams.roleWeb"),
          img: "",
        },
        {
          name: "Frédéric La Madeleine",
          role: this.$t("Teams.roleGroundStation"),
          img: require("@/assets/Fred.png"),
        },
        {
          name: "Claude-David Gaudreault",
          role: this.$t("Teams.rolePayload"),
          img: "",
        },
        {
          name: "Roxanne Watelle",
          role: this.$t("Teams.rolePayload"),
          img: "",
        },
        {
          name: "Maxime Pagé",
          role: this.$t("Teams.roleInfo"),
          img: require("@/assets/MaxPag.png"),
        },
        {
          name: "Laurent",
          role: this.$t("Teams.roleLaurent"),
          img: "",
        },
      ],
    };
    
  },
};
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-image {
    width: 100%;
    height: 100%;
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 20vw);
    justify-content: center;
    gap: 50px;
    padding: 40px 0px;
  }
  &-section {
    background: #222222;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 50px 0;
    width: 80vw;
    justify-content: space-between;
  }
  &-title {
    color: white;
    font-size: 40px;
    margin: 0;
    line-height: 60px;
    margin-top: 40px;
  }
  &-description {
    color: white;
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
    line-height: 30px;
  }
}
@media (max-width: 1500px) {
  .team-grid {
    grid-template-columns: repeat(3, 25vw);
  }
}
@media (max-width: 900px) {
  .team-grid {
    grid-template-columns: repeat(2, 40vw);
  }
  .team-section {
    width: 90vw;
  }
}
@media (max-width: 500px) {
  .team-grid {
    grid-template-columns: repeat(1, 80vw);
  }
}
</style>