<template>
<div>test</div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
#cesiumContainer 
{
	width: 100%;
	height: calc(100vh);
	margin: 0;
	padding: 0;
	margin-bottom: 15px;
}

#cesiumLoading 
{
	position: absolute;
	top: calc((100vh - 2rem) * 0.5);
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3rem;
	height: 3rem;
	z-index: 10002;
}

.alert 
{
    margin-bottom: 0;
}

@media(max-width: 992px)
{
	#cesiumContainer 
	{
		height: calc(100vh);
	}
	
	#cesiumLoading 
	{
		top: calc((100vh - 4rem) * 0.5);
	}
}

@media(min-width: 992px)
{
	#cesiumContainer 
	{
		height: calc(100vh);
	}
	
	#cesiumLoading 
	{
		top: calc((100vh - 2rem) * 0.5);
	}
}
</style>