<template>
  <div class="actualities-container">
    <div class="actualities-header" v-if="actualities.length>0">
      <h1 class="actualities-title">{{ $t("Actualities.title") }}</h1>
    </div>
    <div class="actualities-header" v-else>
      <h1 class="actualities-title">{{ $t("Actualities.noactivity") }}</h1>
    </div>
    <div class="actualities-grid">
      <RouterLink
        style="text-decoration: none"
        v-for="actuality in actualities"
        :key="actuality.id"
        :to="{ path: 'actuality/' + actuality.id }"
      >
        <ActualityCard
          :title="actuality.title"
          :id="actuality.id"
          :date="actuality.date"
          :header="actuality.header"
          :imageUrl="actuality.url"
          :content="actuality.content"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script>
import ActualityCard from "@/components/ActualityCard.vue";

export default {
  components: { ActualityCard },
  name: "Actualities",
  data() {
    return {
      actualities: [],
    };
  },

  
};
</script>

<style lang="scss" scoped>
.actualities-container {
  display: flex;
  min-height: calc(100vh - 200px - 453px);
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.actualities-grid {
  display: grid;
  grid-template-columns: repeat(3, 25vw);
  justify-content: center;
  gap: 50px;
  padding: 40px 0px;
  text-decoration: none;
}
.actualities-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.actualities-title {
  color: white;
  font-size: 40px;
  margin: 0;
  margin-top: 40px;
  line-height: unset;
}

@media (max-width: 900px) {
  .actualities-grid {
    grid-template-columns: repeat(2, 40vw);
  }
}
@media (max-width: 500px) {
  .actualities-grid {
    grid-template-columns: repeat(1, 80vw);
  }
}
</style>