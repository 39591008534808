<template>
  <div class="container">
    <h2 class="title">
      {{ $t("ContactForm.title") }}
    </h2>
    <form class="form-box" @submit="onSubmit">
      <div class="form-row">
        <div class="form-group">
          <label for="fname">{{ $t("ContactForm.firstName") }}</label>
          <input
            v-model="form.name"
            class="form-input"
            name="fname"
            id="fname"
            type="text"
            required
          />
        </div>

        <div class="form-group">
          <label for="lname">{{ $t("ContactForm.lastName") }}</label>
          <input
            v-model="form.familyName"
            class="form-input"
            name="lname"
            id="lname"
            type="text"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="phoneBox">{{ $t("ContactForm.phone") }}</label>
          <input
            v-model="form.phone"
            class="form-input"
            name="lname"
            id="phoneBox"
            type="tel"
          />
        </div>
        <div class="form-group">
          <label for="emailBox">{{ $t("ContactForm.email") }}</label>
          <input
            v-model="form.email"
            class="form-input"
            name="emailBox"
            id="emailBox"
            type="email"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <label for="textBox">Message</label>
        <textarea
          class="form-input form-message"
          v-model="form.text"
          id="textBox"
        ></textarea>
      </div>
      <div class="btn-box">
        <button type="submit" class="btn">
          {{ $t("ContactForm.send") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
//import { mapActions } from "vuex";
export default {
  name: "ContactForm",
  data: function () {
    return {
      form: {},
    };
  },
  methods: {
    //...mapActions(["postEmail"]),
    onSubmit(e) {
      e.preventDefault();
      let body = {
        subject: "Demande d'information",
        text: "",
      };
      body.text += "Nom: " + this.form.name + "\n";
      body.text += "Courriel: " + this.form.email + "\n";
      body.text += "Téléphone: " + this.form.phone + "\n\n";
      if (this.form.text) {
        body.text += "Message:\n\n";
        body.text += this.form.text;
      }
      //this.postEmail(body);
      this.form = {};
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
}
.container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px - 453px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 50px;
}
label {
  font-size: 24px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: auto;
  letter-spacing: normal;
  color: #ffffff;
}
.btn {
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.5s ease-in-out;
  padding: 15px 20px;
  min-width: 150px;
  height: 50px;
  color: #000000;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid #000000;
}
.btn:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  background-color: #000000;
  border: 2px solid #ffffff;
}
.btn-box {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.form-box {
  max-width: 1140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-row {
  width: 100%;
  display: flex;
  gap: 30px;
}
.form-group {
  width: 100%;
}
.form-input {
  width: 100%;
  height: 50px;
  background-color: rgb(214, 214, 214);
  color: #6d6d6d;
  font-size: 16px;
  border: 1px solid #6d6d6d;
  border-radius: 2px;
  padding: 10px;
  outline: none !important;
  box-sizing: border-box;
  box-shadow: none;
  transition: all 0.3s ease;
}
.form-input:focus {
  border: 1px solid #000000;
  color: #000000;
  background-color: #ffffff;
}

.form-message {
  min-width: 100%;
  min-height: 100px !important;
}
</style>
