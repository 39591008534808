<template>
  <div class="Actuality-Card">
    <!-- check if imageUrl prop is provsided and render image tag if true -->
    <img
      class="image"
      :src="require(`@/assets/${imageUrl}`)"
      height="275"
      width="400"
    />
    <div class="Actuality-text">
      <p class="date" :style="{ width: date.length * 7 + 'px' }" @click.prevent>
        {{ date }}
      </p>
      <h2 class="title">{{ header }}</h2>
      <p class="content" @click.prevent>{{ content }}</p>
      <p class="more">VOIR PLUS</p>
    </div>
  </div>
</template>
  
  <script scoped>
export default {
  name: "aSquare",
  mouseON: false,
  props: {
    title: String,
    header: String,
    imageUrl: String,
    id: String,
    date: String,
    content: String,
  },
  methods: {
    handleEvent() {},
  },
};
</script>
  

<style scoped>
.Actuality-Card {
  width: 100%;
  height: 100%;
  background-color: #222222;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
}
.Actuality-text {
  width: 94%;
  text-decoration: none;
  padding: 10px;
}
.title {
  text-align: left;
  color: rgba(227, 227, 227, 0.996);
  font-size: 20px;
  margin-bottom: 5px;
}
.date {
  text-align: left;
  color: rgba(170, 165, 165, 0.996);
  margin-bottom: 5px;
  font-size: 13px;
  cursor: text;
  width: auto;
}
.content {
  text-align: left;
  color: rgba(210, 203, 203, 0.996);
  font-size: 13px;
  line-height: 14px;
  cursor: text;
}
.more {
  text-align: left;
  color: rgba(170, 165, 165, 0.996);
  font-size: 17px;
}
.more:hover {
  text-align: left;
  color: rgba(205, 199, 199, 0.996);
  font-size: 17px;
}
.image {
  height: auto;
  width: 100%;
}
</style>