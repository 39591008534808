<template>
  <div>
    <div class="countdown">
      <!-- Texte qui est ecrit pour le compte a rebours-->
      LANCEMENT >
      <!-- TODO importer et inserer une icone de fleche-->
      <span v-if="displayDays !== 0">{{ displayDays }} <!--{{$t("Home.days")}}--> : </span> {{ dispalyHours }} <!--{{$t("Home.hours")}}--> : {{ displayMinutes }} <!--{{$t("Home.minutes")}}--> :
      {{ displaySeconds }} <!--{{$t("Home.seconds")}}-->
    </div>
  </div>
  <div>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    displayDays: 0,
    dispalyHours: 0,
    displayMinutes: 0,
    displaySeconds: 0,
    end: new Date(2024, 3, 24, 10, 10, 10, 10)
  }),

  computed: {
    _seconds() {
      return 1000;
    },
    _minutes(){
      return this._seconds * 60;
    },
    _hours () {
      return this._minutes * 60;
    },
    _days (){
      return this._hours * 24;
    },
  },

  mounted() {
    this.showRemaining()
  },

  methods: {
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        const distance = this.end.getTime() - now.getTime();

        if (distance < 1000) {
          clearInterval(timer);
          return;
        }

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        
        this.displayMinutes = minutes;
        this.displaySeconds = seconds;
        this.dispalyHours = hours;
        this.displayDays = days;

      },1000);
    },
  },
};
</script>
  

<style>

 .countdown {
  color:#ffffff;
  line-height: 110%;
  font-family: 'Lastica', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 60px;
  font-stretch: ultra-condensed;
 }
 @media (max-width: 1000px){
    .countdown{
      font-size:48px;
    }
 }
 @media (max-width: 1000px){
    .countdown{
      font-size:48px;
    }
 }
 
</style>