<template>
    <div>
        <p class="text" v-if="text">
            <a v-if="$i18n.locale === 'en'" @click="$i18n.locale = 'fr'">FRANCAIS</a>
            <a v-if="$i18n.locale === 'fr'" @click="$i18n.locale = 'en'">ENGLISH</a>
        </p>
        <p class="lang" v-else>
            <a v-if="$i18n.locale === 'en'"  @click="$i18n.locale = 'fr'">FR</a>
            <a v-if="$i18n.locale === 'fr'"   @click="$i18n.locale = 'en'">EN</a>
        </p>
    </div>
</template>


<script>
export default {
    name: "LangButton",
    props: {
        text: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.lang {
    font-size: 16px;
    letter-spacing: 1.6px;
    background: #2b8560;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    color: #000000;
    & a {
        transition: all .3s;
    }
    
}
.text{
    margin: 0;
    font-size: 16px;
    letter-spacing: 1.6px;
    font-weight: bolder;
    color: #ffffff;
}
</style>