<template>
  <div class="QMSatData-container">
    <div class="QMSatData-header" v-if="QMSatData.length>0">
      <h1 class="QMSatData-title">{{ $t("QMSatData.title") }}</h1>
    </div>
    <div class="QMSatData-header" v-else>
      <h1 class="QMSatData-title">{{ $t("QMSatData.noData") }}</h1>
    </div>
    <div class="QMSatData-grid">
      <RouterLink
        style="text-decoration: none"
        v-for="data in datas"
        :key="data.id"
        :to="{ path: 'qmsatdata/' + data.id }"
      >
        <ActualityCard
          :title="data.title"
          :id="data.id"
          :date="data.date"
          :header="data.header"
          :imageUrl="data.url"
          :content="data.content"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script>
import ActualityCard from "@/components/ActualityCard.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { ActualityCard },
  name: "QMSatData",
  data() {
    return {
      QMSatData: [],
    };
  },
  methods:{
    ...mapActions(["getDatas"]),
  },
  computed: {
    ...mapState({
      datasList: (state) => state.qmsat.list,
    }),
  },
};
</script>

<style lang="scss" scoped>
.QMSatData-container {
  display: flex;
  min-height: calc(100vh - 200px - 453px);
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.QMSatData-grid {
  display: grid;
  grid-template-columns: repeat(3, 25vw);
  justify-content: center;
  gap: 50px;
  padding: 40px 0px;
  text-decoration: none;
}
.QMSatData-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.QMSatData-title {
  color: white;
  font-size: 40px;
  margin: 0;
  margin-top: 40px;
  line-height: unset;
}

@media (max-width: 900px) {
  .QMSatData-grid {
    grid-template-columns: repeat(2, 40vw);
  }
}
@media (max-width: 500px) {
  .QMSatData-grid {
    grid-template-columns: repeat(1, 80vw);
  }
}
</style>