<template>
  <div>
    <div class="title-box">
      <h2 class="page-title">
        <img
          class="logo"
          src="@/assets/logo.png"
          alt="Logo"
          width="50"
          height="50"
        />
        Qu'est-ce que le QMsat
      </h2>
    </div>
    <div class="container">
      
      <div class="container-left">
        <h2>QMsat</h2>
        <p>
          Le satellite que vous voyez sur cette page est le projet actuel de
          sirius! Ce cubesat se nomme QMSat. Il a été conçu dans le carde d'un
          projet ce conception majeur par des étudaints de l'université de
          sherbrooke pour la fin de leur bac. À la fin du projet, ce satellite
          nous a été légué et nous sommes maintenant en train de le préparer
          pour son voyage dans l'esapce!
        </p>
      </div>
      <div class="container-right">
        <img src="@/assets/satelite.png" style="width: 100%; heigh: auto" />
      </div>
    </div>
    <div class="container">
      <div class="container-left">
        <h2>UN CUBESAT?</h2>
        <p>
          Un cubesat est un petit satellite miniature de forme cubique qui pèse
          autour de 1kg. Ce type de satellite est populaire pour sa petite
          taille, pour le coût relativement bas et pour sa facilité de
          fabrication. Ces satellites sont utililsés pour plusieurs types de
          mission,, autant pour des missions scientifiques que pour des
          entreprises ou projets d'études
        </p>
      </div>
      <div class="container-right">
        <video style="width: 100%; heigh: auto" id="cubesat" controls>
          <source src="@/assets/video.mp4" type="video/mp4" />
        </video>
        <a class="link">
          https://www.asc-csa.gc.ca/videos/recherche/1_emzq6svm/1_lo656xyc.webm
        </a>
      </div>
    </div>

    <div class="container">
      <div class="container-elems">
        <div class="container-elems-item">
          <img src="@/assets/logo_lait.png" alt="" width="70" height="70" />
          <h3>
            {{ $t("QMSat.size") }}
          </h3>
        </div>
        <div class="container-elems-item">
          <img src="@/assets/logo_bras.png" alt="" width="70" height="70" />
          <h3>
            {{ $t("QMSat.arm") }}
          </h3>
        </div>
        <div class="container-elems-item">
          <img
            src="@/assets/logo_quantique.png"
            alt=""
            width="70"
            height="70"
          />
          <h3>
            {{ $t("QMSat.magnetometer") }}
          </h3>
        </div>
      </div>
      <div class="container-box">
        <img src="@/assets/real_qmsat.jpg" style="width: 100%; height: auto" />
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      description: [
        {
          title: "Satellite de la taille d une brique de lait",
          img: "../assets/logo_lait.png",
          id: 1,
        },
        {
          title: "Muni d'un bras déployable en trois temps",
          img: "../assets/logo_bras.png",
          id: 2,
        },
        {
          title: "Son payload est un magnétomètre quantique",
          img: "../assets/logo_quantique.png",
          id: 3,
        },
      ],
    };
  },
};
</script>
    
<style lang="scss" scoped>
.container {
  width: 100%;
  padding-top: 100px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 150px;
  align-items: center;
  align-content: flex-start;
  &-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: justify;
  }
  &-right {
    width: 50%;
    a {
      display: block;
    }
  }
  &-box {
    width: 25%;
    order: 1;
    img {
      flex-basis: 30%;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 5%;
      border-radius: 25%;
    }
  }
  &-elems {
    text-align: left;
    margin-bottom: 20px;
    order: 2;
    h3 {
      font-size: 20px;
      color: white;
    }
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }
    img {
      border-radius: 25%;
    }
  }
}

.page-title {
  font-size: 40px;
  margin-top:0;
  font-weight: bold;
  color: white;
  text-align: center;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item1 img {
  border-radius: 25%;
}

@media (max-width: 1000px) {
  .page-title {
    font-size: 25px;
  }
  .container {
    flex-direction: column;
    gap: 50px;
    &-left {
      width: 60%;
      &-title {
        font-size: 25px;
        margin: 0;
      }
      &-text {
        font-size: 16px;
        margin: 0;
      }
    }
    &-right {
      width: 80%;
      a {
        display: none;
      }
    }
    &-box {
      width: 65%;
      order: 2;
    }
    &-elems {
      width: 80%;
      order: 1;
    }
  }
}
</style>
