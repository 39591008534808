<template>

  
<div>  
   <div class="homepage-picture">
      <img
      src="@/assets/satelite.png"
      alt="Homepage picture"
      style="width:50%;height:auto"
      />
   </div>
   
   <div class="text-box">
      <Countdown/>
      <p class="subText">
         Restez à l'affut des nouvelles dans la section <router-link class="link" to="/actualities">actualités</router-link>!
      </p>
      <p class="subtext" style="margin-top:50px;margin-bottom:0">
         Les données du satellite seront ouvertes au public! Allez voir dans la section <router-link class="link" to="/qmsat">QMsat</router-link> pour en savoir plus
      </p>
   </div>
   
   
   
</div>

<!-- Quick qui nous sommes-->

</template>

<script>
import Countdown from '../components/Countdown.vue';
export default {
   components:{
      Countdown
   }
};
</script>

<style scoped>
.text-box{
   margin-left: 5%;
   padding-top: 5%;
   color: #ffffff;
}
.subText{
   margin: 0;

}

.homepage-picture {
   padding-top: 5%;
   margin-bottom: 0%;
   margin-left: 5%;
   position: relative;
   left: -150px;
}


</style>