
<!--
  <template>
    <div >
      <div class="background">
  
      </div>
      <img
      class="background"
      src="./assets/Background_Page_Accueil_Essai3_Reduced_size.gif"
    />
      <NavBar/>
      <router-view class="content"/>
      <Footer/>
    </div>
  </template> 
  
-->

<template>
  <div>
    <div class="background">
      <video autoplay muted loop class="background-video">
        <source src="./assets/Background_Page_Accueil_Essai3_Reduced_size.mp4" type="video/mp4" />
      </video>
    </div>
    <NavBar/>
    <router-view class="content"/>
    <Footer/>
  </div>
</template>



<script>
import Footer from './components/Footer.vue';
import NavBar from './components/NavBar.vue';
export default {
  //this is a comment
  components: {
    Footer, 
    NavBar
  },
  name: 'App',
  mounted(){
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss">
/* this is a comment */

.content {
  background: rgba(0,0,0,.2);
  
  top: 100px;
} 

.link{
   color: unset;
   text-decoration: none;
   cursor: pointer;

}
.link:hover{
   text-decoration: underline;
}
.background {
  
  // background-image: url(./assets/Background_Page_Accueil_Essai3_Reduced_size.gif);
  // background-position: right; /* Center the image */
  // background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -350px;
  bottom: 0;
  left: 0;
  z-index: -1;
  .background-video {
    position: absolute;
    //top: -20px;
    top: 50px;
    right: -275px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000000;
  background-color: #000000;
  overflow-x: hidden;
}
</style>
