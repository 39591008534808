<!-- page contenant l'actualité propre (texte) -->

<!--le wrapper ne marche pas-->
<template>
  <div class="container">
    <article class="actuality">
      <div class="article-title-box">
        <p class="article-date">{{ actuality.date }}</p>
        <h1 class="article-title">{{ actuality.title }}</h1>
      </div>
      
      <img
        class="article-image"
        :src="actuality.image"
        alt="Description de la photo"
      />
      <p class="article-text">{{ actuality.p1 }}</p>
      <p class="article-text">{{ actuality.p2 }}</p>
    </article>
  </div>
</template>

<script>
//import image1 from "@/assets/logo.png"
import image2 from "@/assets/first-slide.png";
export default {
  props: ["header"],
  data() {
    return {
      actualities: [
        {
          image: image2,
          title: "Page de la première actualité",
          header: "Premiere",
          id: 1,
          url: "logo.png",
          date: "Le 5 février 2023",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
        {
          image: image2,
          title: "Page de la deuxième actualité",
          header: "Deuxieme",
          id: 2,
          url: "first-slide.png",
          date: "Le 2 février 2023",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
        {
          image: image2,
          title: "Page de la troisième actualité",
          header: "Troisieme",
          id: 3,
          url: "first-slide.png",
          date: "Le 27 janvier 2023",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
        {
          image: image2,
          title: "Page de la quatrième actualité",
          header: "Quatrieme",
          id: 4,
          url: "first-slide.png",
          date: "Le 2 décembre 2022",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
        {
          image: image2,
          title: "Page de la cinquième actualité",
          header: "Cinquieme",
          id: 5,
          url: "first-slide.png",
          date: "Le 13 novembre 2022",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
        {
          image: image2,
          title: "Page de la sixième actualité",
          header: "Sixieme",
          id: 6,
          url: "first-slide.png",
          date: "Le 9 octobre 2022",
          p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
          p2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis obcaecati excepturi unde alias amet suscipit, laboriosam, voluptate dicta illum earum hic odit nisi tempora modi porro soluta sed commodi consequatur sapiente provident! Voluptates dicta saepe consequuntur doloremque magnam veritatis ratione nemo tempora dolore? Quos ipsum error perferendis necessitatibus, doloremque rerum expedita ea cum, nam, aliquam modi veniam architecto possimus repudiandae quidem ut debitis! Incidunt est architecto similique, voluptatum itaque, dolore eaque culpa dolorum maxime earum corporis. Aspernatur qui voluptatum commodi nam et, ut cupiditate quia laudantium dolorum, mollitia velit perspiciatis repellat. Pariatur saepe iure asperiores, amet perferendis laudantium optio delectus ab animi dolorem accusamus recusandae, quasi ad ducimus dolore nesciunt ullam, itaque magni totam nihil sequi reiciendis quo libero. Exercitationem quam possimus distinctio, autem perspiciatis minima illo voluptate dolores expedita nobis blanditiis iusto culpa doloribus eos veniam quae voluptatum quidem dignissimos ipsa ipsam eum, deserunt soluta sequi? Iste, ipsam vero. Error facere saepe quibusdam at fugiat assumenda eveniet labore nostrum qui excepturi architecto cum provident reprehenderit debitis, quaerat odit velit autem quasi animi officia ullam distinctio nam? Minus rem laborum placeat tenetur quod iusto ab, ipsum quasi aliquid deserunt explicabo quis recusandae fugiat error ratione quos nulla atque delectus tempora aspernatur dolorem, aperiam voluptate tempore. Quisquam commodi, praesentium exercitationem nulla dignissimos, illum eligendi ullam delectus molestiae dolorum impedit quos quae nemo dolorem deleniti repellendus ea aut inventore suscipit, aliquam sint nam. Illo excepturi ut blanditiis ex consequatur, sequi dolorum dolore pariatur saepe modi cumque nihil quibusdam corporis eos? Debitis, quam.",
        },
      ],

      actuality: {
        title: "",
      },
      id: 0,
    };
  },
  mounted() {
    this.id = parseInt(this.$route.params.id);
    for (let actuality of this.actualities) {
      if (actuality.id === this.id) {
        this.actuality = actuality;
      }
    }
  },
};
</script>
background-color: rgba(255, 255, 255, 0.527);
color: black;
border: 2px solid white;

<style scoped>
.container {
  min-height: calc(100vh - 150px - 453px);
  padding-top: 60px !important;
  margin: auto;
  padding: 10px 100px;
  color: #ffffff;
  font: arial;
  font-size: 16px;
  line-height: 1.5;
}
.actuality{
  max-width: 1200px;
  margin: auto;
}
.article-title-box{
  margin-bottom:50px;
}
.article-title {
  text-align: justify;
  margin:0;
}
.article-date {
  text-align: justify;
  margin:0;
}
.article-image {
  max-width: 100%;
  float: right;
  margin-left: 10px;
}
.article-text {
  align-content: justify;
  text-align: justify;

}
@media(max-width: 1300px) {
  .container {
    padding: 10px 50px;
  }
}
</style>
