import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Actualities from '../views/Actualities.vue'
import Position from '../views/Position.vue'
import Team from '../views/Team.vue'
import Actuality from '../views/Actuality.vue'
import QMSat from '../views/QMSat.vue'
import QMSatData from '../views/QMSatData.vue'


const routes = [
    { path: '/', name: 'home', component: Home },
    { path: '/contact', name: 'contact', component: Contact },
    { path: '/actualities', name: 'actualities', component: Actualities },
    { path: '/team', name: 'team', component: Team },
    { path: '/position', name: 'position', component: Position },
    {
        path: '/actuality/:id',
        name: 'actuality',
        component: Actuality,
        props: true
    },
    { path: '/qmsat', name: 'qmsat', component: QMSat },
    { path: '/qmsatdata', name: 'qmsatdata', component: QMSatData },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    next();
});

export default router