import { createApp } from 'vue'
import App from './App.vue'
import { i18n } from "./plugins/i18n";
import router from './router'
import store from './store'

const app = createApp(App).use(store).use(i18n).use(router)

import BootstrapVue3 from 'bootstrap-vue-3'

app.use(BootstrapVue3)


app.mount('#app')