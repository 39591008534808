
<template>
  <div class="footer">
    <div class="leftSection">
      <div class="leftSection-logoBox">
        <router-link class="nav-footer" to="/"
          ><img
            class="leftSection-logo"
            width="100%"
            height="100%"
            src="@/assets/logoFooter.png"
          />
        </router-link>
      </div>
      <div class="leftSection-text">
        {{ $t("Footer.text") }}
      </div>
      <div class="socialMedia">
        <a href="https://www.facebook.com/SiriusUdeS/" target="_blank">
          <img
            class="socialMedia-logo"
            src="../assets/facebook.svg"
            alt="Facebook Logo"
            width="100%"
            height="100%"
          />
        </a>
        <a href="https://www.instagram.com/sirius_udes/" target="_blank">
          <img
            src="../assets/instagram.svg"
            alt="Instagram Logo"
            width="100%"
            height="100%"
          />
        </a>
        <a href="https://twitter.com/qmsatellite" target="_blank">
          <img
            class="socialMedia-logo"
            src="../assets/twitter.svg"
            alt="twitter Logo"
            width="100%"
            height="100%"
          />
        </a>
        <a href="https://www.linkedin.com/company/qmsat/" target="_blank">
          <img
            class="socialMedia-logo"
            src="../assets/linkedin.svg"
            alt="twitter Logo"
            width="100%"
            height="100%"
          />
        </a>
      </div>
    </div>
    <div class="rightSection">
      <div class="rightSection-line"></div>
        <div class="rightSection-column">
          <h5 class="rightSection-column-title">
            {{ $t("Footer.background") }}
          </h5>
          <div class="rightSection-items">
            <router-link to="/team"> {{ $t("Footer.team") }} </router-link>
            <router-link to="/">{{ $t("Footer.history") }}</router-link>
          </div>
        </div>
        <div class="rightSection-column">
          <h5 class="rightSection-column-title">
            {{ $t("Footer.communication") }}
          </h5>
          <div class="rightSection-items">
            <router-link to="/">
              {{ $t("Footer.result") }}
            </router-link>
            <router-link to="/"> {{ $t("Footer.position") }} </router-link>
          </div>
        </div>
        <div class="rightSection-column">
        <h5 class="rightSection-column-title">
          {{ $t("Footer.project") }}
        </h5>
        <div class="rightSection-items">
          <router-link to="/qmsat"> {{ $t("Footer.QMSat") }} </router-link>
          <router-link to="/">{{ $t("Footer.future") }}</router-link>
        </div>
      </div>
      </div>
      
  </div>
</template>
  
  <script>
export default {
  name: "Footer",
};
</script>



  <style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  //min-height: 100%;
  width: 100vw;
  background: rgba(0,0,0,.2);
  margin: auto;
}

.leftSection {
  flex-direction: column;
  padding-right: 30px;
  display: flex;
  width: 30%;
  align-items: center;
  &-logoBox {
    height: auto;
    width: 250px;
  }
  &-text {
    color: #ffffff;
    text-align: center;
    width: 300px;
    line-height: 30px;
    padding: 20px 0px;
  }
  &-logo {
    height: 100%;
    width: 100%;
  }
}

.socialMedia {
  display: flex;
  width: 200px;
  justify-content: space-between;
  &-logo {
    height: 30px;
    width: 30px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    width: 40px !important;
    background: #ffffff;
    border-radius: 100%;
  }
}

.rightSection {
  display: flex;
  flex-direction: row;
  &-line {
    background-color: #e6c038;
    border-radius: 5px;
    height: 240px;
    width: 2px;
    margin-right: 20px;
  }
  h5 {
    font-size: 20px !important;
    margin-bottom: 10px;
    font-weight: bolder;
  }
  a {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    color: rgba(#ffffff, 0.9);
    font-size: 16px !important;
    font-weight: bold;
    text-decoration: none;
  }
  a:hover {
    color: #2b8560;
    text-decoration: underline;
  }
  &-column {
    margin-top: 30px;
    padding: 0px 20px;
    &-title {
      color: #737373;
    }
  }
}



@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
    width: 100%;
  }
  .leftSection {
    padding-right: 0px;
    padding-bottom: 30px;
    max-width: 550px;
  }


.rightSection {
  display: flex;
  width: 60%;
  flex-direction: column;
  &-column {
    margin-top: 0px;
    padding: 0;
    &-title {
      color: #737373;
    }
  }
  &-line {
    display: none;
  }
  h5 {
    margin-top: 0px;
    font-size: 18px !important;
  }
  a {
    margin-left: 10px;
    font-size: 16px !important;
  }
}

}


</style>
  