<template>
    <div class="team-card">
        <img v-if="member.img" :src="member.img" :alt="'Image de '+ member.name "  class="team-img">
        <p class="team-name">{{member.name}}, <span class="team-title">{{member.role}}</span></p>
    </div>
</template>

<script>
export default {
    props: {
        member:Object
    },

}
</script>

<style lang="scss" scoped>  
    .team-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background: #222222;
    }
    .team-title{
        font-size: 11px;
        font-style: italic;
    }
    .team-img{
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
    }
    .team-name{
        color: #c2c2c2;
        font-size: 12px;
        margin: 0;
        margin-top: 10px;
    }
</style>